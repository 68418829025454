/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// Userway Widget
export const onClientEntry = () => {
    const script = document.createElement('script')
    script.src = 'https://cdn.userway.org/widget.js'
    script.setAttribute('data-account', 'S19yu70nmL')
    document.head.appendChild(script)
}
