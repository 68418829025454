module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TEKFIRM","short_name":"TEKFIRM","start_url":"/","background_color":"#ffffff","theme_color":"#008eed","display":"standalone","icon":"src/assets/images/icons/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"776c4d3ef5a9db5777e394a6b7d03c43"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NGDQJTJ","routeChangeEventName":"tekfirm-route-change","includeInDevelopment":false,"defaultDataLayer":null},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
